import './index.css';
import '@vismaux/vud/dist/css/vud.min.css';
import '@vismaux/vud-icons/dist/css/vud-icons.min.css';


import React from 'react';
import { createRoot }from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {SelfService} from "./components/SelfService";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = createRoot(document.getElementById('root'));

rootElement.render(
  <BrowserRouter basename={baseUrl}>
    <Routes>
      <Route path="/" element={<SelfService />} />
    </Routes>
  </BrowserRouter>
);