import React, { useState, useEffect } from 'react';
import Recaptcha from 'react-google-recaptcha';
import logotypeeAccounting from '../assets/icons/96-eEkonomi-logomark.png';
import logotypeVLS from '../assets/icons/96-Lön-logomark.png';

export const SelfService = () => {
    const [state, setState] = useState({
        message: "",
        responseHttpStatusCode: "",
        responseErrorCode: "",
        email: "",
        companyName: "",
        firstName: "",
        lastName: "",
        country: "Sweden",
        recaptcha: "",
        isLoading: false,
        isTOSOk: false,
        recaptchaSiteKey: "",
        purpose: "Private",
        reRenderRecaptcha: false
    });

  const recaptchaRef = React.createRef();

    useEffect(() => {
        if (state.recaptchaSiteKey === "") {
            fetch('api/Provisioning/GetCaptchaKey', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json'
                }
            })
              .then((resp) => resp.json())
              .then((json) => {
                  setState(prevState => ({ ...prevState, 'recaptchaSiteKey': json }));
              });
        }
    }, [state.recaptchaSiteKey]);
    
    useEffect(() => {
      if (state.responseHttpStatusCode === 409){
        setState(prevState => ({ ...prevState, responseHttpStatusCode: "" }));
      }
    }, [state.email, state.companyName])
  
    useEffect(() => {
      if (recaptchaRef?.current !== null && state.reRenderRecaptcha) {
        recaptchaRef.current.reset();
        setState(prevState => ({ ...prevState, reRenderRecaptcha: false }));
      }
    }, [recaptchaRef, state.reRenderRecaptcha]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setState(prevState => ({ ...prevState, isLoading: true }));

        fetch('api/Provisioning/ProvisionPartner', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify(state)
        })
          .then(responseMessage => {
            if (responseMessage.status === 409) {
              responseMessage.text().then((text) => {
                setState(prevState => ({ ...prevState,
                  responseHttpStatusCode: responseMessage.status,
                  responseErrorCode: JSON.parse(text),
                  recaptcha: "",
                  isLoading: false,
                  reRenderRecaptcha: true
                }));
              });
            }
            else {
              setState(prevState => ({ ...prevState,
                responseHttpStatusCode: responseMessage.status,
                email: '',
                companyName: "",
                firstName: "",
                lastName: "",
                country: "Sweden",
                recaptcha: "",
                isLoading: false,
                recaptchaSiteKey: ""
              })) 
            }});
    }

    const handleChange = (type, event) => {
        setState(prevState => ({ ...prevState, [type]: event.target.value }));
    };

    const handleChangeTOS = (type, event) => {
        setState(prevState => ({ ...prevState, [type]: event.target.checked }));
    };

    const onRecaptchaChange = (response) => {
        setState(prevState => ({ ...prevState, 'recaptcha': response }));
    };

  const resultResponse = () => {
      const { responseHttpStatusCode } = state;

      if (responseHttpStatusCode === 200){
          return (<div className="alert alert-success" role="alert">
              <strong>Registration successful!</strong> Please check your email for further instructions.
          </div>)
      } else if (responseHttpStatusCode && responseHttpStatusCode !== 200 && responseHttpStatusCode !== 409){
          return (<div className="alert alert-danger" role="alert">
              <strong>Oops! Something went wrong.</strong> Please contact eAccountingAPI@visma.com.
          </div>)
      }
  }
    
  const isSuccessfull = () => {
    return state.responseHttpStatusCode === 200;
  }

  const companyAlreadyExist = () => {
    return state.responseHttpStatusCode === 409 && state.responseErrorCode === "4001";
  }

  const userAlreadyExist = () => {
    return state.responseHttpStatusCode === 409 && state.responseErrorCode === "4002";
  }

    let formIsValid = !!state.email &&
      !!state.firstName &&
      !!state.lastName &&
      !!state.companyName &&
      !!state.recaptcha &&
      !!state.isTOSOk &&
      !!!state.isLoading &&
      (!!!companyAlreadyExist() && !!!userAlreadyExist());

    if (state.recaptchaSiteKey.length === 0) {
        return null;
    }
    
    const inputEmailClassName = userAlreadyExist() ? "form-group required-field has-error" : "form-group required-field"; 
    const inputCompanyNameClassName = companyAlreadyExist() ? "form-group required-field has-error" : "form-group required-field"; 

    
    return (
    <body className="login-page">
    <div className="login-box">
      <div className="login-header">
        <div className="row">
          <div className="col-6">
            <img src={logotypeeAccounting} alt="Product logotype"/>
          </div>
          <div className="col-6">
            <img src={logotypeVLS} alt="Product logotype"/>
          </div>
        </div>
        <h1>Visma eAccounting/Lön Smart API</h1>
        <h3>After registration you will get an email with the information needed to access the API. Get started <a href="https://developer.vismaonline.com" target="_blank">here</a>.</h3>
      </div>

      <div className="login-content">
        {resultResponse()}
        { !isSuccessfull() &&
            <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="form-group required-field">
                      <div className="row">
                          <div className="col-6">
                              <label htmlFor="inputFirstName" className="control-label">First Name</label>
                              <input id="inputFirstName"
                                     type="text"
                                     className="form-control"
                                     placeholder="First Name"
                                     required-field={true}
                                     value={state.firstName}
                                     disabled={state.isLoading}
                                     onChange={(event) => handleChange('firstName', event)}
                              />
                          </div>
                          <div className="col-6">
                              <label htmlFor="inputLastName" className="control-label">Last Name</label>
                              <input id="inputLastName"
                                     type="text"
                                     className="form-control"
                                     placeholder="Last Name"
                                     value={state.lastName}
                                     disabled={state.isLoading}
                                     onChange={(event) => handleChange('lastName', event)}
                              />
                          </div>
                      </div>
                  </div>

              <div className={inputEmailClassName}>
                <label htmlFor="inputEmail" className="control-label">E-mail Address</label>
                <input id="inputEmail"
                       type="email"
                       className="form-control"
                     placeholder="Email"
                     value={state.email}
                     disabled={state.isLoading}
                     onChange={(event) => handleChange('email', event)}
                />
                {userAlreadyExist() &&
                  <div className="error-text">Email address already in use. Please try again!</div>
                }
              </div>

              <div className={inputCompanyNameClassName}>
              <label htmlFor="inputCompanyName" className="control-label">Company</label>
                <input id="inputCompanyName"
                       type="text"
                       className="form-control"
                       placeholder="Company Name"
                       value={state.companyName}
                       disabled={state.isLoading}
                       onChange={(event) => handleChange('companyName', event)}
                />
                {companyAlreadyExist() &&
                  <div className="error-text">Company name already in use. Please try again!</div>
                }
              </div>
              <div className="form-group required-field">
                      <label htmlFor="inputCountry" className="control-label">Country</label>
                      <select className="form-control"
                              name="Country"
                              id="inputCountry"
                              value={state.country}
                              disabled={state.isLoading}
                              onChange={(event) => handleChange('country', event)}
                      >
                          <option value="Sweden">Sweden</option>
                          <option value="Norway">Norway</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Finland">Finland</option>
                      </select>
                  </div>
                  <div className="form-group required-field">
                      <label htmlFor="inputPurpose" className="control-label">Purpose</label>
                      <select className="form-control" name="Purpose" id="purpose"
                              value={state.purpose}
                              disabled={state.isLoading}
                              onChange={(event) => handleChange('purpose', event)}
                      >
                          <option value="Private">Private use</option>
                          <option value="Commercial">Commercial use</option>
                      </select>
                  </div>

                  <div className="form-group">
                      <div className="checkbox">
                          <input type="checkbox" value="" onChange={(event) => handleChangeTOS('isTOSOk', event)}
                                 id="inputCheckbox"
                                 disabled={state.isLoading}
                          />
                          <label htmlFor="inputCheckbox" className="control-label">
                              I accept the <a
                            href="https://vismaeasandboxbeta.blob.core.windows.net/eaccounting-api-terms-of-service/VismaDeveloperTerms-23.04.2020.pdf"
                            target="_blank" rel="noopener noreferrer">Terms Of Service</a>
                          </label>
                      </div>
                  </div>
                  <div className="form-group">
                      <Recaptcha
                        ref={recaptchaRef}
                        sitekey={state.recaptchaSiteKey}
                        onChange={onRecaptchaChange.bind(this)}
                      />
                  </div>
                  {state.isLoading &&
                    <div className="horizontal-loading">
                        <div className="loading-bar" role="progressbar">
                            <span className="sr-only">Loading bar</span>
                        </div>
                    </div>
                  }
                  <div className="form-group">
                      <button type="submit"
                              className="btn btn-primary btn-login"
                              disabled={!formIsValid}
                      >
                          Sign up
                      </button>
                  </div>
              </form>
        }
          </div>

          <div className="login-footer">
              <a href="https://visma.com" className="logo-footer">Visma</a>
          </div>
      </div>
      </body>
    );
}